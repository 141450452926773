<template>
  <div class="mx-3">
    <h2 class="px-5 py-5">{{ $t('Details Trips Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                          v-model="filters.from" clearable>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                          v-model="filters.to" clearable>
        </v-text-field>
      </v-col>
      <v-col md="4">
        <v-autocomplete 
          v-model="filters.student_id"
            :label="$t('student name')"
            clearable :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchRegRecordAutoComplete($event)"
            >
          </v-autocomplete>
      </v-col>
      <v-col md="2">
            <v-text-field
              :label="$t('Trip ID')"
              v-model="filters.trip_id"
              type="number"
              clearable
            ></v-text-field>
        </v-col>
      <v-col md="3">
          <v-autocomplete :items="routeItems" item-text="name" item-value="id" v-model="filters.route_id"
                                :search-input.sync="routeSearch" :loading="driverRoute.routeNameState.loading"
                                :label="$t('Route Name')" :hint="$t('requird')"></v-autocomplete>
        </v-col>
     
        <v-col md="8">

        </v-col>

      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block  @click="exportToExcel(filters)"
          >{{ $t('hr.time.export') }}
          </v-btn>
      </v-col>
    </v-row>



    <v-data-table :dark="$store.state.isDarkMode"
      :headers="headers"
      :loading="loading"
      :items="tableItems"
      class="elevation-1 px-10"
      hide-default-footer
      :items-per-page="10"
    >
    <template  v-slot:item.student_name="{ item }" >
      
      <v-btn @click="detailsHandlerStu(item)"  >
        {{ item.student_name }}
        </v-btn
      >
    </template>
    <template  v-slot:item.wait_time="{ item }" >
      
      <v-btn v-if="item.wait_time" @click="detailsHandlerWait(item)"  >
        {{ item.wait_time }}
        </v-btn
      >
    </template>
    <template  v-slot:item.attend_time="{ item }" >
      
      <v-btn v-if="item.attend_time" @click="detailsHandlerAtt(item)"  >
        {{ item.attend_time }}
        </v-btn
      >
    </template>
    <template  v-slot:item.absent_time="{ item }" >
      
      <v-btn v-if="item.absent_time" @click="detailsHandlerAtt(item)"  >
        {{ item.absent_time }}
        </v-btn
      >
    </template>
    </v-data-table>
    <v-pagination
      v-if="!loading && tableItems.length"
      @input="getAll"
      v-model="page"
      :length="Math.ceil(total / 10)"
    ></v-pagination>
      
        <!-- <my-map v-if="dialog" :dialog="dialog"></my-map> -->
    
         
                      

  </div> 
</template>

<script>
import { driver } from '../../../../store/drivers'
import { driverRoute } from '../../../../store/driverRoutes'
import axios from 'axios'
import i18n from '@/i18n';
import debounce from "../../../../helpers/debounce";
import spinner from "../../../../components/base/spinner.vue";
import pagination from "../../../../components/base/pagination.vue";
// import map from '../../busRoute/getMapComponent.vue'
import { saveAs } from 'file-saver';


export default {
  components: {
  appSpinner: spinner,
  appPagination: pagination,
  // myMap: map,
   
},
  data: () => ({
    driver,
    driverRoute,
    dialog: false,
    driverSearch: null,
    routeSearch: null,
    is_all: false,
    paginated: true,
    grades: null,
    gradesLoading: false,
    eduClasses: null,
    eduClassesLoading: false,
    childrens: null,
    childrensLoading: false,
    tableItems: [],

    loading: false,
    page: 1,
    total: 1,
    // tableHeaders: ["date", "route name", "driver name",  "Number Students", "Absent","Start Time", "End Time", "Details"],
    headers: [  
      { text: i18n.t('student name'), value: "student_name" },
      { text: i18n.t('Ordering'), value: "order" },
      { text: i18n.t('Wait Time'), value: "wait_time" },
      { text: i18n.t('Attend Time'), value: "attend_time" },
      { text: i18n.t('Absent Time'), value: "absent_time"},
     
      
    ],
    
  

    filters: {
      route_id: null,
      driver: null,
      student_count: null,
      absent_count: null,
      from: null,
      to: null,
      trip_id: null,
     
    },
  }),
  computed: {
      items() {
          return driverRoute.driver
      },
     
  },
  watch: {
      driverSearch(val) {
          if (val != '') {
              driverRoute.storeDriverName(val)
          } else {
              driverRoute.driver = []
          }
      },
      routeSearch(val) {
          if (val != '') {
              driverRoute.getrouteName(val)
          } else {
              driverRoute.route = []
          }

      },

  },

  methods: {
    detailsHandlerStu(item) {
      window.open(`https://maps.google.com/?q=${item.student_loc.lat},${item.student_loc.lng}`); 
    },
    detailsHandlerWait(item) {
      window.open(`https://maps.google.com/?q=${item.wait_loc.lat},${item.wait_loc.lng}`); 
    },
    detailsHandlerAtt(item) {
      window.open(`https://maps.google.com/?q=${item.attend_loc.lat},${item.attend_loc.lng}`); 
    },
   
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
              type: "full_name",
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
    goToAll(){
    if(this.is_all) {
      this.paginated = false;
    } else { this.paginated = true; }
    this.getAll();
  },

  async exportToExcel(queryParams){
       
       try {
  
   const excel = await axios.get(`/student_trips/export`, {
     params: {
       ...queryParams,
       
     },
     responseType: 'blob'
   });
   saveAs(excel.data)
   

 } catch (err) {
   console.log('err', err);
 } finally {
   
 }
   
   },
 
    clearFilter() {
      this.filters.route_id =  undefined;
      this.filters.driver = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.student_count = undefined; 
      this.filters.absent_count = undefined;
    
      this.page = 1;
      this.getAll();
    },
 
    async getAll() {
      try {
       
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        filterTemp['trip_id'] = this.$route.query.trip_id;
        this.loading = true;
        
        const res = await axios.get(`/student_trips/report`, {
          params: {
            size: 10,
            page: this.page,
            paginated: this.paginated,
            ...filterTemp,
          },
        });
        const arr= Array.from(res.data.data)
        if(Array.isArray(arr))
        {
          console.log(arr);
          this.tableItems = arr.map((el) => {
            
            return {
              ...el,
              
            };
          });
        }
          console.log(res.data.data);
      //     this.tableItems.forEach((item) => {
      //   item.uid = this.$uuid.v1();
      // });
        this.total = res.data.meta.total;
        this.page = res.data.meta.current_page;
      } finally {
        this.loading = false;
      }
    },
    applyPagination(paginationValue) {
    this.page = paginationValue;
    this.getAll();
  },
    async groupAc() {
          try {
        const searchResult = await axios.get(
          "/meal-group"
        );
        this.meal_group = searchResult.data.data;
      } catch (err) {
        console.log("err", err);
      } 
    },

  },
  async created() {
    this.getAll();
   
  },
};
</script>

<style></style>
